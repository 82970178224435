import { Component, OnInit } from '@angular/core';
import { ESETPartnerService } from '../../services/eset.partner.service';
import { Select, Store } from '@ngxs/store';
import { PartnerState } from '../../../partner/state/partner.state';
import { GetPartner, GetPartnersSelf } from '../../../partner/state/partner.actions';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPartner } from '../../../partner/models/partner.model';
import { ESETMigrationObject } from '../../models/ESETMigrationObject';
import { ESETConfirmMigration } from '../../models/ESETConfirmMigration';
import { GetPartnerMigrationStatus } from '../../state/partner/eset.partner.actions';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm-eset-move',
  templateUrl: './confirm-eset-move.component.html',
  styleUrls: ['./confirm-eset-move.component.scss'],
})
export class ConfirmEsetMoveComponent implements OnInit {
  @Select(PartnerState.getAuthenticatedPartner) partner$;

  partner: IPartner;
  migrationObject: ESETMigrationObject = undefined;
  isLoading = true;
  confirmMoveObject: ESETConfirmMigration;
  isConfirming = false;

  protected _onDestroy = new Subject<void>();

  constructor(
    private esetPartnerService: ESETPartnerService,
    private store: Store,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetPartnersSelf());

    this.partner$.pipe(takeUntil(this._onDestroy)).subscribe((partnerSelf) => {
      this.partner = partnerSelf;
    });

    this.setMigrationData();
  }

  confirmMove() {
    this.isConfirming = true;

    this.confirmMoveObject = {
      partnerId: this.partner.id,
      companyId: this.migrationObject.companyId,
    };

    this.esetPartnerService
      .confirmMoveOfPartnerESETCompanyToCloudFactory(this.confirmMoveObject)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.isConfirming = false;
          this.toastr.success('Confirmation received. Your migration will now be handled by ESET and you will receive an email when it is done', 'Confirm migration');
          this.store.dispatch(new GetPartnerMigrationStatus({ partnerId: this.partner.id }));
          this.setMigrationData();
        },
        (error) => {
          this.toastr.error('An error occured with your confirmation' + error, 'Confirm migration error');
          this.isConfirming = false;
        },
      );
  }

  private setMigrationData() {
    this.partner$
      .pipe(
        takeUntil(this._onDestroy),
        tap((partner: IPartner) => (this.partner = partner)),
        switchMap((partner: IPartner) => {
          if (!partner) {
            return of(null);
          }
          return this.esetPartnerService.getPartnerMigrationDetails(partner.id).pipe(
            tap((result) => {
              this.migrationObject = result;
              this.isLoading = false;
            }),
          );
        }),
      )
      .subscribe();
  }

  public goToPortal() {
    this.router.navigateByUrl('/home');
  }
}
